// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---node-modules-bluefin-gatsby-plugin-fisherman-checkout-templates-checkout-checkout-jsx": () => import("./../../../../../node_modules/@bluefin/gatsby-plugin-fisherman-checkout/templates/Checkout/Checkout.jsx" /* webpackChunkName: "component---node-modules-bluefin-gatsby-plugin-fisherman-checkout-templates-checkout-checkout-jsx" */),
  "component---node-modules-bluefin-gatsby-plugin-fisherman-checkout-templates-order-confirmation-order-confirmation-jsx": () => import("./../../../../../node_modules/@bluefin/gatsby-plugin-fisherman-checkout/templates/OrderConfirmation/OrderConfirmation.jsx" /* webpackChunkName: "component---node-modules-bluefin-gatsby-plugin-fisherman-checkout-templates-order-confirmation-order-confirmation-jsx" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-benefits-of-our-ingredients-jsx": () => import("./../../../src/pages/benefits-of-our-ingredients.jsx" /* webpackChunkName: "component---src-pages-benefits-of-our-ingredients-jsx" */),
  "component---src-pages-catering-jsx": () => import("./../../../src/pages/catering.jsx" /* webpackChunkName: "component---src-pages-catering-jsx" */),
  "component---src-pages-community-jsx": () => import("./../../../src/pages/community.jsx" /* webpackChunkName: "component---src-pages-community-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-hiring-jsx": () => import("./../../../src/pages/hiring.jsx" /* webpackChunkName: "component---src-pages-hiring-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-menu-jsx": () => import("./../../../src/pages/menu.jsx" /* webpackChunkName: "component---src-pages-menu-jsx" */),
  "component---src-pages-schedule-food-trailer-jsx": () => import("./../../../src/pages/schedule-food-trailer.jsx" /* webpackChunkName: "component---src-pages-schedule-food-trailer-jsx" */)
}

